// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11005(93be8e869e)-C10/12/2024-12:23:39-B10/12/2024-12:40:00' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11005(93be8e869e)-C10/12/2024-12:23:39-B10/12/2024-12:40:00",
  branch: "master",
  latestTag: "6.1",
  revCount: "11005",
  shortHash: "93be8e869e",
  longHash: "93be8e869eb01084fca5135b6007aa6c9c316bb7",
  dateCommit: "10/12/2024-12:23:39",
  dateBuild: "10/12/2024-12:40:00",
  buildType: "Ansible",
  } ;
